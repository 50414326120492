<template>
  <BasePageContent>
    <VendorDetail :uuid="uuid"></VendorDetail>
  </BasePageContent>
</template>

<script>
import VendorDetail from "@/components/ui/vendor/VendorDetail.vue";
export default {
  components: { VendorDetail },
  props: {
    uuid: {
      type: String,
    },
  },
};
</script>